<template>
<section class="sign-in d-flex flex-column">
  <div class="btn-back d-flex" @click="back()">
    <img src="@/assets/icons/Arrow-left.svg" alt="">
    <h6 class="title">Back</h6>
  </div>
  <div class="content">
    <h3>Hello!</h3>
    <h3>Welcome back</h3>
    <form class="d-flex flex-column" @submit.prevent>
      <label>
        <input type="text" placeholder="Email*"
               :class="{'error':$v.userInfo.login.$error}"
               v-model="$v.userInfo.login.$model">
      </label>
      <label>
        <input type="password" placeholder="Password*"
               :class="{'error':$v.userInfo.password.$error}"
               v-model="$v.userInfo.password.$model">
      </label>
      <p class="forgot" @click="openModal">Forgot?</p>
      <div class="next-step d-flex ">
        <div class="btn-continue-shop" @click="transitionToSignUp">
          Become a member
        </div>
        <h6 class="subtitle">Do not have an account yet?</h6>
        <div class="btn-next-step" @click="nextPage" >
          <h6>Log in</h6>
        </div>
      </div>
    </form>
    <ForgotPasswordEmail ref="forgotEmail" />
  </div>
</section>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
export default {
  name: "SignIn",
  mixins:[validationMixin],
  data(){
    return{
      userInfo:{
        login:'',
        password:'',
      },
      submitStatus:null,
    }
  },
  components:{
    ForgotPasswordEmail:()=>import('@/components/Modals/ForgotPassword/ForgotPasswordEmail/ForgotPasswordEmail'),

  },
  methods:{
    back(){
      this.$router.push('/')
    },
    transitionToSignUp(){
      this.$router.push('/signUp')
    },
    nextPage(){
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        console.log(this.submitStatus)
      } else {
        this.$store.dispatch('loginByEmail',this.userInfo)
        // this.$router.push('/checkoutPersonalInfo')
      }
    },
    openModal(){
      this.$refs.forgotEmail.open()
    },
  },
  validations:{
    userInfo:{
      login:{
        required
      },
      password:{
        required
      },
    },
  },
}
</script>

<style scoped lang="scss">
@import "SignIn.scss";
</style>